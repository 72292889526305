<template>
  <section>
    <div class="container">
        <h2>
            A digitalizáció és az innováció
            elhivatott specialistái vagyunk.
        </h2>
    </div>
  </section>
</template>

<style lang="scss" scoped>
section {
    background-color: var(--c3);
    position: relative;
    min-height: 500px;
    --d: 350px;
}
section:before,
section:after {
    content: '';
    border: 1px solid var(--c4);
    width: var(--d);
    height: var(--d);
    position: absolute;
    border-radius: calc(var(--d) / 2);
    bottom: calc(var(--d) / -2);
    animation: DecorAnim .8s ease-in-out 0s 1 normal forwards;
    opacity: 0;
}
section:before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    right: 0;
    margin-right: calc(var(--d) / 2);
    animation-delay: 0.6s;
}
section:after {
    bottom: calc(var(--d) / -2);
    right: calc(var(--d) / -2);
    animation-delay: 1s;
}
@keyframes DecorAnim {
    0%   { opacity: 0; transform: translateX(100px); }
    100% { opacity: 1; transform: translateX(0); }
}
h2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    animation: TextAnim 1s ease-in-out 0s 1 normal forwards;
    font-size: 60px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    width: 50%;
}
h2:before {
    content: '';
    position: absolute;
    width: 36px;
    height: 58px;
    transform: translateY(-100px);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 91 146'%3E%3Cpath fill='transparent' stroke='%23fff' stroke-width='5px' d='m5.2 142.91 40.41-69.99 40.41 69.99H5.2ZM5.2 2.93l40.41 69.99L86.02 2.93H5.2Z'/%3E%3C/svg%3E");
    background-size: cover;
    animation: TextAnim .4s ease-in-out 0s 1 normal forwards;
    animation-delay: .6s;
    opacity: 0;
}
@media (max-width: 1199px) {
    h2 {
        font-size: 5vw;
        width: auto;
    }
    h2:before {
        transform: translateY(-100px);
    }
}
@media (max-width: 767px) {
    section {
        min-height: 250px;
        --d: 200px;
    }
    h2 {
        font-size: 6vw;
    }
    h2:before {
        transform: scale(.5) translateY(-150px);
        transform-origin: bottom left;
    }
}
@keyframes TextAnim {
    0%   { opacity: 0; margin-top: 45px; }
    100% { opacity: 1; margin-top: 0; }
}
</style>