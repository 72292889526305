<template>
    <Waypoint>
        <section :style="{
            backgroundImage: 'url(' + require('@/assets/img/ndalegal-folder.jpg') + ')'
        }">
            <div class="container">
                <div>
                    <h2>Amiben gyors megoldást hozunk</h2>
                    <ul>
                        <li>GDPR katasztrófa elkerülése</li>
                        <li>Elavult adatvédelem felülvizsgálása</li>
                        <li>Szerzői jogok állandó változásáról való tájékoztatás</li>
                        <li>
                            Rémálmok megszűntetése a Licensz szerződések miatt<br>
                            (nem értünk az álomfejtéshez, csak mi csinálnánk helyetted)
                        </li>
                        <li>DPO - folyamatos jogi támogatás</li>
                        <li>Nyereményjátékok és hűségprogramok indítása</li>
                        <li>Hírlevélküldés, vagy más érdeklődőgyűjtő kampányok megfelelőssége</li>
                    </ul>
                    <h2>Esetek, amikor gyors és szakértő partnerként segítünk</h2>
                    <ul>
                        <li>Új szoftvert fejlesztesz, vagy vezetsz be</li>
                        <li>Külsős partner részére üzemeltetsz</li>
                        <li>Nagy forgalmú webshopod van</li>
                        <li>Nincs, vagy elavult az ÁSZF-ed</li>
                        <li>Ügyfélreklamációt kezelsz</li>
                        <li>Rés van a pajzsodon</li>
                        <li>
                            Növekedési fázisban lévő startup vagy és minden jogi feladatban<br>
                            segítségre van szükséged, legyen szó akár cégjogról, munkajogról,<br>
                            vagy a megfelelő helyiségbérleti szerződés kialakításáról
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </Waypoint>
</template>

<script>
import { Waypoint } from 'vue-waypoint'
export default {
    components: { Waypoint }
}
</script>

<style lang="scss" scoped>
section {
    padding-top: 120px;
    background-size: 0;
    background-position: 500% 40%;
    background-repeat: no-repeat;
    transition: 3s;
}
.waypoint.going-in section {
    opacity: 1;
    background-position: 100% 40%;
    background-size: 900px;
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
li {
    padding-left: 30px;
    position: relative;
}
li:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border: 2px solid var(--c4);
    border-bottom: 0;
    border-left: 0;
    transform: rotate(45deg);
    top: 6px;
    left: 0;
}
.container > div {
    display: flex;
    flex-wrap: wrap;
}
@media (max-width: 991px) {
    .container > div {
        display: block;
    }
    h2 {
        margin-bottom: 30px;
    }
}
.container > div h2 {
    flex-basis: 25%;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 150%;
    color: var(--c2);
    font-weight: 400;
    text-transform: uppercase;
    opacity: 0;
    transition: .6s all;
    transition-delay: 1s;
    transform: translateY(30px);
    padding: 0 30px;
}
.container > div h2:last-of-type {
    transition-delay: 1.2s;
}
.waypoint.going-in h2 {
    opacity: 1;
    transform: translateY(0);
}
.container > div ul {
    flex-basis: 75%;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 60px;
}
</style>