<template>
  <Waypoint>
    <section>
        <div class="container" :style="{
            backgroundImage: 'url(' + require('@/assets/img/specialities/background.svg') + ')'
        }">
            <h2>Szakterületeink</h2>
            <ul>
                <li>
                    <img :src="getAssetURL('img/specialities/adatvedelem.png')" alt="Adatvédelem" />
                </li>
                <li>
                    <img :src="getAssetURL('img/specialities/informatikai-es-szerzoi-jog.png')" alt="Informatikai és szerzői jog" />
                </li>
                <li>
                    <img :src="getAssetURL('img/specialities/digitalis-gazdasagi-jog.png')" alt="Digitális gazdasági jog" />
                </li>
            </ul>
            <ul>
                <li>
                    <h5>Adatvédelem</h5>
                    <ol>
                        <li>GDPR - helyes adatkezelési gyakorlat</li>
                        <li>Adatvédelmi tanácsadás</li>
                        <li>Teljes adatvédelmi átvilágítás</li>
                        <li>Adatvédelmi tisztviselő szolgáltatás</li>
                    </ol>
                </li>
                <li>
                    <h5>Informatikai és szerzői jog</h5>
                    <ol>
                        <li>Szoftver&shy;fejlesztési és üzemeltetési szerződések</li>
                        <li>Startup-ok jogi támogatása</li>
                        <li>Szellemi alkotások védelme</li>
                        <li>Védjegyek </li>
                    </ol>
                </li>
                <li>
                    <h5>Digitális gazdasági jog</h5>
                    <ol>
                        <li>ÁSZF és adatkezelési tájékoztató készítése</li>
                        <li>Webshop kialakításához jogi tanácsadás</li>
                        <li>Online reklámmal és marketinggel<br>kapcsolatos jogi tanácsadás</li>
                        <li>Digitális üzletkötés</li>
                    </ol>
                </li>
            </ul>
            <h3>További szakterületeink</h3>
            <div>
                <h5>Munkajog</h5>
                <h5>Cégjog</h5>
                <h5>szerződések joga</h5>
            </div>
            <a href="#" class="link-with-big-arrow">Tevékenységeinkről részletesen</a>
        </div>
    </section>
  </Waypoint>
</template>

<script>
import { Waypoint } from 'vue-waypoint'
export default {
    components: { Waypoint },
    methods: {
        getAssetURL: function(data) {
            return require('../assets/' + data)
        }
    }
}
</script>

<style lang="scss" scoped>
section {
    text-align: center;
}
ul:first-of-type {
    position: relative;
    margin-bottom: 60px;
}
ul:first-of-type:before {
    content: '';
    position: absolute;
    background: var(--c3);
    width: 500%;
    left: -200%;
    height: 82.5%;
    z-index: -1;
}
ul, ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
ul {
    display: flex;
}
@media (max-width: 991px) {
    ul:nth-of-type(2) {
        display: block;
    }
    ol {
        margin-bottom: 60px;
    }
    h5 {
        padding-left: 15%;
        padding-right: 15%;
    }
}
ul > li {
    flex-basis: 33.33%;
}
img {
    width: 100%;
}
h2 {
    padding: 75px 0;
    position: relative;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
}
h2:before {
    content: '';
    position: absolute;
    background: var(--c3);
    width: 500%;
    left: -200%;
    height: 110%;
    top: 0;
    z-index: -1;
}
h2, h5 {
    text-transform: uppercase;
}
h5 {
    color: var(--c2);
    position: relative;
    padding-bottom: 60px;
}
li h5:after {
    content: '';
    position: absolute;
    bottom: 29px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 1px;
    background: var(--c4);
}
ol {
    color: var(--c2);
    font-size: 15px;
    line-height: 1.75em;
}
.container > div:last-of-type {
    display: flex;
    margin-bottom: 30px;
}
.container > div:last-of-type h5 {
    flex-basis: 33.33%;
    padding: 5px;
}
@media (max-width: 991px) {
    .container > div:last-of-type {
        display: block;
    }
}
h3 {
    color: var(--c2);
    position: relative;
    padding-bottom: 60px;
    font-weight: 400;
    text-transform: uppercase;
    padding-top: 60px;
}
h3:after {
    content: '';
    position: absolute;
    bottom: 29px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 1px;
    background: var(--c4);
}

.waypoint ul:first-of-type li:nth-of-type(1) img,
.waypoint ul:first-of-type li:nth-of-type(2) img,
.waypoint ul:first-of-type li:nth-of-type(3) img {
    opacity: 0;
    transition: 1s all ease-in-out;
}
.waypoint ul:first-of-type li:nth-of-type(1) img {
    transform: translateX(-60px);
    transition-delay: 1.4s;
}
.waypoint ul:first-of-type li:nth-of-type(2) img {
    transform: translateX(-60px);
    transition-delay: .6s;
}
.waypoint ul:first-of-type li:nth-of-type(3) img {
    transform: translateX(60px);
    transition-delay: 1s;
}
.waypoint.going-in ul:first-of-type li:nth-of-type(1) img,
.waypoint.going-in ul:first-of-type li:nth-of-type(2) img,
.waypoint.going-in ul:first-of-type li:nth-of-type(3) img {
    opacity: 1;
    transform: translateX(0);
}
@media (max-width: 991px) {
    .waypoint.going-in ul:first-of-type li:nth-of-type(1) img,
    .waypoint.going-in ul:first-of-type li:nth-of-type(2) img,
    .waypoint.going-in ul:first-of-type li:nth-of-type(3) img {
        opacity: 1;
        transform: translateX(0) scale(1.5);
    }
    h3 {
        padding-top: 0;
    }
}
.container {
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: 815px;
    padding-bottom: 200px;
    position: relative;
}
.container:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: -50%;
    margin-right: -200px;
    width: 100%;
    border-bottom: 123px solid var(--c7);
    border-left: 150px solid transparent;
}
.container:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -50%;
    margin-left: -200px;
    width: 100%;
    border-bottom: 123px solid var(--c7);
    border-right: 150px solid transparent;
}
@media (max-width: 991px) {
    .container {
        background-size: 500px;
    }
    .container:before {
        right: -50%;
        margin-right: -125px;
        border-bottom: 80px solid var(--c7);
        border-left: 100px solid transparent;
    }
    .container:after {
        left: -50%;
        margin-left: -125px;
        border-bottom: 80px solid var(--c7);
        border-right: 100px solid transparent;
    }
}
@media (max-width: 575px) {
    .container {
        background-size: 300px;
        padding-bottom: 100px;
    }
    .container:before {
        right: -50%;
        margin-right: -70px;
        border-bottom: 45px solid var(--c7);
        border-left: 60px solid transparent;
    }
    .container:after {
        left: -50%;
        margin-left: -70px;
        border-bottom: 45px solid var(--c7);
        border-right: 60px solid transparent;
    }
}
</style>