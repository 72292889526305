<template>
    <section>
        <div class="container">
            <div>
                <h2>
                    Fókuszban
                </h2>
                <p>
                    Az NDAlegal azzal a céllal alakult, hogy elhozza az ügyvédi irodák új generációját a magyar piacra. Célunk, hogy harmóniát teremtsünk a gazdaság, technológiai újítások és jog között, így szakértői szinten vagyunk naprakészek technológiai témákkal kapcsolatos jogi területeken.
                </p>
                <p>
                    Nekünk ugyanúgy <strong>szívügyünk a céged sikere,</strong> mint neked.<br>
                    Pontosan ezért friss és támogatói szemlélettel tevékenykedünk minden nap.<br>
                    Szenvedélyünk az üzletileg hatékony és jogszabályoknak megfelelő jogi tanácsadás, legyen szó adatvédelemről, informatikai innovációról, cégügyekről vagy munkajogi kérdésekről.
                </p>
                <p>
                    Saját fejlesztésű digitális jogi megoldásainkkal egyszerűbbé és gyorsabbá tettük a cégalapítást, és egyszerűbb jogi dokumentumok előkészítését – ezzel időt és pénzt spórolunk Neked. Próbáld ki az @NDA szolgáltatásunkat és intézd velünk jogi ügyeidet online!
                </p>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
section {
    background-color: var(--c6);
}
section div.container div {
    max-width: 60%;
    padding-top: 75px;
    padding-bottom: 75px;
    position: relative;
}
@media (max-width: 991px) {
    section div.container div {
        max-width: 100%;
    }
}
h2 {
    margin-bottom: 30px;
    color: var(--c5);
    text-transform: uppercase;
    font-weight: 400;
    animation: TextAnim 2s cubic-bezier(0.680, -0.550, 0.265, 1.550) 0s 1 normal forwards;
    animation-delay: 0.8s;
    opacity: 0;
}
p {
    animation: TextAnim 2s cubic-bezier(0.680, -0.550, 0.265, 1.550) 0s 1 normal forwards;
    opacity: 0;
}
p:nth-of-type(1) { animation-delay: 1.0s; }
p:nth-of-type(2) { animation-delay: 1.2s; }
p:nth-of-type(3) { animation-delay: 1.4s; }
@keyframes TextAnim {
    0%   { opacity: 0; transform: translateY(30px); }
    100% { opacity: 1; transform: translateY(0); }
}
section div.container div:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: -500%;
    width: 500%;
    height: 0px;
    border-left: 155px solid transparent;
    border-bottom: 125px solid var(--c3);
}
</style>