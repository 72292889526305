<template>
  <slider-component />
  <in-focus-component />
  <specialities-components />
  <at-nda-component />
  <solutions-component />
  <div style="height: 2000px"></div>
</template>

<script>
import AtNdaComponent from '../components/AtNdaComponent.vue'
import InFocusComponent from '../components/InFocusComponent.vue'
import SliderComponent from '../components/SliderComponent.vue'
import SolutionsComponent from '../components/SolutionsComponent.vue'
import SpecialitiesComponents from '../components/SpecialitiesComponents.vue'
export default {
  components: { SliderComponent, InFocusComponent, SpecialitiesComponents, AtNdaComponent, SolutionsComponent },

}
</script>

<style>

</style>